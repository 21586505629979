import Link from "next/link";

import { buildCategoryUrl } from "~/utils/route";
import _ from "lodash";

const STYLE_MAPPINGS = {
  talk: {
    backgroundColor: "#87C6B3",
    color: "#FFFFFF",
  },
  share: {
    backgroundColor: "#e7f3f0",
    color: "#91cbba",
  },
  faq: {
    backgroundColor: "#fde9e8",
    color: "#F3938E",
  },
  story: {
    backgroundColor: "#d8edff",
    color: "#1890FF",
  },
  jd: {
    backgroundColor: "#fdefcf",
    color: "#FFAE0E",
  },
  new: {
    backgroundColor: "#D9D7F1",
    color: "#3D087B",
  },
  talent: {
    backgroundColor: "#f9ba48",
    color: "#FFFFFF",
  },
  cv: {
    backgroundColor: "#F19794",
    color: "#FFFFFF",
  },
  normal: {
    backgroundColor: "#F5F3E7",
    color: "#877D6A",
  },
};

const LIFE_STYLE_MAPPINGS = {
  吃喝玩乐: {
    backgroundColor: "#C7D4F5",
    color: "#1C4474",
  },
  买房租房: {
    backgroundColor: "#FFCFBA",
    color: "#A84419",
  },
  交通: {
    backgroundColor: "#C6F4F7",
    color: "#13858A",
  },
  教育: {
    backgroundColor: "#FECBD4",
    color: "#C02944",
  },
  医疗: {
    backgroundColor: "#D9EED1",
    color: "#4D753E",
  },
  交友: {
    backgroundColor: "#D4D1EE",
    color: "#37006D",
  },
};

// helpers
const { normal: defaultStyle } = STYLE_MAPPINGS;
const codeToStyle = (code) => STYLE_MAPPINGS[code] || defaultStyle;
const tagToStyle = (tag) => LIFE_STYLE_MAPPINGS[tag.name] || defaultStyle;

export default (props) => {
  const url = buildCategoryUrl(props.category.id);
  const life_tag = _.find(props.tags, (tag) => tag.tag_group.code === "life");
  return (
    <Link legacyBehavior href={url.href} as={url.as}>
      {life_tag ? (
        <a
          className={`category-link ${props.className}`}
          style={tagToStyle(life_tag)}>
          {`${props.category.name}·${life_tag.name}`}
        </a>
      ) : (
        <a
          className={`category-link ${props.className}`}
          style={codeToStyle(props.category.code)}>
          {props.category.name}
          {props.tags &&
            props.tags.map((tag) => {
              if (
                props.category.code === "jd" &&
                tag.tag_group.code === "job_type"
              ) {
                return `·${tag.name}`;
              }
            })}
        </a>
      )}
    </Link>
  );
};

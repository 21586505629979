import Link from "next/link";

import { truncate } from "~/utils/app";
import { userUrl } from "~/utils/route";
import UserAvatar from "~/components/user/UserAvatar";

/**
 * 用户链接组件，显示包含用户头像，昵称，等级等信息的链接
 *
 * @param {object} user 用户信息
 * @param {Boolean} isAuthor 是否标记为作者，默认为 false
 * @param {Boolean} showAvatar 是否显示头像，默认为 false
 * @param {Boolean} avatar 是否显示头像，默认为 false (deprecated, 使用 showAvatar 替代)
 * @param {Boolean} showLevel 是否显示等级，默认为 true
 * @param {int} size 头像大小，默认为 23
 */
const UserLink = (props) => {
  const {
    user,
    avatar = false,
    showLevel = true,
    size = 23,
    isAuthor = false,
  } = props;
  // TODO: deprecated avatar prop
  const showAvatar = "showAvatar" in props ? props.showAvatar : avatar;

  return (
    <Link legacyBehavior href={userUrl(user)}>
      <a className={props.className} target="_blank">
        {showAvatar && (
          <UserAvatar showLevel={showLevel} user={user} size={size} />
        )}
        <span className={`ml-5 ${props.className}`} title={user.nickname}>
          {truncate(user.nickname, 12)}
        </span>
        {isAuthor && <span>（作者）</span>}
      </a>
    </Link>
  );
};

export default UserLink;

// views
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { Tooltip, Button } from "antd";
import {
  PushpinFilled,
  PushpinOutlined,
  HomeOutlined,
  CrownOutlined,
} from "@ant-design/icons";
import UserAvatar from "~/components/user/UserAvatar";
import UserLink from "~/components/user/UserLink";
import CategoryLink from "~/components/post/CategoryLink";
import FeaturedIcon from "~/public/static/icons/featured.svg";
import Badge from "~/components/app/Badge";
import Image from "next/image";
import PostVoteAction from "~/components/post/PostVoteAction";

// utils
import { timeAgo, highlightContent } from "~/utils/app";
import classNames from "classnames";
import { setScrollPosition } from "../../utils/ui";
import { postUrl, getHref } from "~/utils/route";

export default (props) => {
  const router = useRouter();
  const isSearchPath = router.pathname === "/search";
  const { post: aPost, extra, onCancelHidePost } = props;
  // const [isHide, setIsHide] = useState(aPost.hide);
  const [visibility, setVisibility] = useState();
  const [post, setPost] = useState();

  const className = classNames("post-item", { deleted: aPost.deleted });

  // views
  const pinnedMark = (
    <Tooltip title="已全站置顶">
      <PushpinFilled className="ml-5 text-sm text-primary text-bottom" />
    </Tooltip>
  );
  const pinnedInCategoryMark = (
    <Tooltip title="已本版置顶">
      <PushpinOutlined className="ml-5 text-sm text-primary text-bottom" />
    </Tooltip>
  );
  const pinnedInCityMark = (
    <Tooltip title="已在本城市内部置顶">
      <HomeOutlined className="ml-5 text-sm text-primary text-bottom" />
    </Tooltip>
  );
  const featuredMark = (
    <Tooltip title="已加精">
      <span className="ml-5 text-middle">
        <Image src={FeaturedIcon} width={11} heigth={15} alt="已加精"></Image>
      </span>
    </Tooltip>
  );
  const limitedMark = (
    <Tooltip title="关注可见">
      <span className="ml-5 text-middle">
        <CrownOutlined className="ml-5x text-primary text-bottom" />
      </span>
    </Tooltip>
  );
  const renderCommentInfo = () => (
    <span>
      <UserLink user={post.last_comment.user} className="mr-5" />
      {timeAgo(post.last_comment_at)}
      回复
    </span>
  );
  const renderPublishInfo = () => (
    <span>发布于 {timeAgo(post.published_at)}</span>
  );

  // useEffect(() => {
  //   setIsHide(aPost.hide);
  // }, [aPost.hide]);
  useEffect(() => {
    setVisibility(aPost.visibility);
  }, [aPost]);

  useEffect(() => {
    setPost(aPost);
  }, []);

  if (!post) return null;
  return (
    <div className={className}>
      {/* {props.withVote && (
        <PostVoteAction
          post={post}
          upvoted={props.upvoted}
          downvoted={props.downvoted}
        />
      )} */}
      <div className="aside">
        <UserAvatar user={post.user} />
      </div>
      <div className="body">
        <h2 className="post-title">
          <Link legacyBehavior href={getHref(post)} as={postUrl(post)}>
            <a target="_blank">
              {visibility === "hidden" && (
                <span className="post-title__hidden-tag">[已隐藏]</span>
              )}
              {visibility === "internal" && (
                <span className="post-title__hidden-tag">[内部可见]</span>
              )}
              {visibility === "private" && (
                <span className="post-title__hidden-tag">[私密]</span>
              )}
              {isSearchPath && props.keyword ? (
                <div
                  className="post-title"
                  dangerouslySetInnerHTML={{
                    __html: highlightContent(props.keyword, post.title),
                  }}></div>
              ) : (
                post.title
              )}
            </a>
          </Link>
          {post.featured && featuredMark}
          {post.pinned && !post.pinned_in_category && pinnedMark}
          {!post.pinned && post.pinned_in_category && pinnedInCategoryMark}
          {post.pinned_in_city && pinnedInCityMark}
          {post.paid_type === "limited" && limitedMark}

          {/* {isHide && (
              <Button
                type="link"
                size="small"
                onClick={() => {
                  onCancelHidePost();
                  setIsHide(false);
                }}
              >
                取消隐藏
              </Button>
            )} */}
        </h2>
        <div className="meta-info">
          <span>
            <CategoryLink category={post.category} tags={post.tags} />
          </span>

          <span>
            <UserLink user={post.user} />
          </span>

          {post.contributors.length > 0 && (
            <span>
              <UserLink user={post.contributors[0]} />
            </span>
          )}

          {post.contributors.length > 0 && (
            <span>共{post.contributors.length + 1}人</span>
          )}

          {renderPublishInfo()}
          {post.last_comment && renderCommentInfo()}
          {extra}
        </div>
      </div>
      <div className="extra">
        <Badge count={post.comments_count} />
      </div>
    </div>
  );
};
